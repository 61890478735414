.cardContainerShadow {
  box-shadow: inset 10px 0 6px -6px rgba(0, 0, 0, 0.05), inset -10px 0 6px -6px rgba(0, 0, 0, 0.05);
}

.cardSizing {
  min-width: 450px;
  height: 365px;
}

@media only screen and (max-width: 767px) {
  .cardSizing {
    min-width: 250px;
    height: 365px;
  }
}

.modalBackground {
  background-color: rgba(0, 0, 0, 0.5);
}

.modalBody {
  max-height: 60vh;
}